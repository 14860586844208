.container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 20px 0px 0px 20px;
}

.apiCellContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.nameLabel {
  width: 200px;
}

.statusContainer {
  width: 300px;
  text-align: center;
  border-radius: 10px;
  margin-left: 20px;
  padding: 10px 0px 10px 0px;
}

.green {
  background-color: green;
  color: white;
}

.red {
  background-color: red;
  color: white;
}

.pink {
  border: 1px solid black;
}
